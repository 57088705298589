import dynamic from 'next/dynamic';

import { TilesSliceProps } from '@ui/slice-machine/slices/tiles-slice/TilesSlice';

const TilesSlice = dynamic<TilesSliceProps>(() =>
  import('@ui/slice-machine/slices/tiles-slice/TilesSlice').then(
    (val) => val.TilesSlice
  )
);

export default TilesSlice;
